@media all and (min-width: 480px) {
    .DashboardView {
      padding: 60px 0;
    }
  
    .DashboardView form {
      margin: 0 auto;
      max-width: 320px;
    }
  }

  .custom-tooltip {
    border:solid 1px #bdbdbd;
    border-radius: 2px;
    background-color: white;
    /* position: absolute; */
    box-shadow: 0px 2px 2px 0px rgba(204, 204, 204, 0.6);
    font-size: 12px;
    /* color: #82ca9d; */
    padding: 0px;
    -moz-box-shadow: 0px 2px 2px 0px rgba(204, 204, 204, 0.6);
    -webkit-box-shadow: 0px 2px 2px 0px rgba(204, 204, 204, 0.6);
  }


